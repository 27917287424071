/* eslint-disable complexity */
import { ThemeName, generatePalette, getPalette, IconPosition } from '@antv/s2';
import { TTemplate } from '../../../types/template';
import { TTrainingResult } from '../../../types/trainingResult';
import { Calculator } from '../../../libs/calculators';
import { addCommas, numberWithCommas } from '../../../libs/helpers/functions';

export const OPTIONS_TABLE1 = {
  style: {
    colCfg: {
      widthByFieldValue: {
        value1: 150,
        value2: 150,
        value3: 150,
      },
    },
    rowCfg: {
      width: 150,
    },
  },
  mergedCellsInfo: [
    [
      { colIndex: 1, rowIndex: 1 },
      { colIndex: 1, rowIndex: 2 },
      { colIndex: 1, rowIndex: 3, showText: true },
      { colIndex: 1, rowIndex: 4 },
      { colIndex: 1, rowIndex: 5 },
    ],
    [
      { colIndex: 1, rowIndex: 7 },
      { colIndex: 1, rowIndex: 8 },
      { colIndex: 1, rowIndex: 9, showText: true },
      { colIndex: 1, rowIndex: 10 },
    ],
    [
      { colIndex: 2, rowIndex: 1 },
      { colIndex: 2, rowIndex: 2 },
      { colIndex: 2, rowIndex: 3, showText: true },
      { colIndex: 2, rowIndex: 4 },
      { colIndex: 2, rowIndex: 5 },
    ],
    [
      { colIndex: 2, rowIndex: 7 },
      { colIndex: 2, rowIndex: 8 },
      { colIndex: 2, rowIndex: 9, showText: true },
      { colIndex: 2, rowIndex: 10 },
    ],
  ],
  customSVGIcons: [
    {
      name: 'PERCENT',
      svg: '/images/percent.svg',
    },
  ],
  conditions: {
    icon: [
      {
        field: 'value1',
        position: 'left' as IconPosition,
        mapping(field: any) {
          if (typeof field !== 'number') {
            return { icon: '', fill: '' };
          }
          return {
            icon: 'DOLLAR',
            fill: '#00FF00',
            margin: 1,
          };
        },
      },
      {
        field: 'value2',
        position: 'left' as IconPosition,
        mapping(field: any) {
          if (typeof field !== 'number') {
            return { icon: '', fill: '' };
          }
          return {
            icon: 'DOLLAR',
            fill: '#000000',
          };
        },
      },
      {
        field: 'value3',
        position: 'right' as IconPosition,
        mapping(field: any) {
          if (typeof field !== 'number') {
            return { icon: '', fill: '' };
          }
          return {
            icon: 'PERCENT',
            fill: '#000000',
          };
        },
      },
    ],
  },
};

export const OPTIONS_TABLE2 = {
  height: 130,
  style: {
    rowCfg: {
      width: 140,
    },
  },
};

export const THEME_TABLE = {
  name: 'colorful' as ThemeName,
  palette: generatePalette({
    ...getPalette('colorful'),
    brandColor: '#1D81BA',
  }),
};

export const OPTIONS_TABLE3 = {
  height: 80,
};

export const OPTIONS_TABLE4 = {
  height: 200,
  style: {
    rowCfg: {
      width: 140,
    },
  },
};

const TTargetYear1 = 1;

export const configTable1 = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);
  cal.updateTrainingResult(trainingResult);

  const configTable1 = {
    fields: {
      rows: ['title', 'title2'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '大項目',
      },
      {
        field: 'title2',
        name: '小項目',
      },
      {
        field: 'value1',
        name: '項目別金額',
      },
      {
        field: 'value2',
        name: '合計',
      },
      {
        field: 'value3',
        name: '売上に対する割合',
      },
    ],
    data: [
      {
        title: '売上',
        value1: '-',
        value2: numberWithCommas(cal.Y101(TTargetYear1)),
        value3: 100,
        title2: '売上',
      },
      {
        title: '売上原価',
        title2: '支払地代(賃借料)',
        value1: numberWithCommas(cal.isLandRented() ? cal.Y211() : 0),
        value2: ' ',
        value3: ' ',
      },
      {
        title: '売上原価',
        value1: numberWithCommas(cal.Y501(TTargetYear1)),
        value2: numberWithCommas(cal.Y102(TTargetYear1)),
        value3: ' ',
        title2: '減価償却費',
      },
      {
        title: '売上原価',
        title2: '燃料費',
        value1: numberWithCommas(cal.Y1100()),
        value2: numberWithCommas(cal.Y102(TTargetYear1)),
        value3: addCommas((cal.Y102(TTargetYear1) / cal.Y101(1)) * 100, '%', 2),
      },
      {
        title: '売上原価',
        title2: '消耗品・資材等経費',
        value1: numberWithCommas(cal.Y1400()),
        value2: ' ',
        value3: ' ',
      },
      // {
      //   title: '売上原価',
      //   title2: '栽培技術コンサル人件費',
      //   value1: numberWithCommas(cal.Y1200()),
      //   value2: ' ',
      //   value3: ' ',
      // },
      {
        title: '売上原価',
        title2: 'パート人件費',
        value1: numberWithCommas(cal.Y1009()),
        value2: ' ',
        value3: ' ',
      },
      {
        title: '売上総利益',
        title2: '売上総利益',
        value1: '-',
        value2: numberWithCommas(cal.Y103(TTargetYear1)),
        value3: addCommas(cal.Y104(TTargetYear1), '%', 2),
      },
      {
        title: '販売費',
        title2: '販促活動費',
        value1: numberWithCommas(cal.Y702()),
        value2: ' ',
        value3: ' ',
      },
      {
        title: '販売費',
        title2: '梱包材料費',
        value1: numberWithCommas(cal.Y708(TTargetYear1)),
        value2: ' ',
        value3: ' ',
      },
      {
        title: '販売費',
        title2: '物流・輸送費',
        value1: numberWithCommas(cal.Y712(TTargetYear1)),
        value2: numberWithCommas(cal.Y700(TTargetYear1)),
        value3: addCommas(cal.Y701(TTargetYear1), '%', 2),
      },
      {
        title: '販売費',
        title2: 'その他販売経費',
        value1: numberWithCommas(cal.Y715(TTargetYear1)),
        value2: ' ',
        value3: ' ',
      },
      {
        title: '売上総利益 - 販売費',
        title2: '売上総利益-販売費',
        value1: '',
        value2: numberWithCommas(cal.Y103(TTargetYear1) - cal.Y700(TTargetYear1)),
        value3: addCommas(((cal.Y103(TTargetYear1) - cal.Y700(TTargetYear1)) / cal.Y101(TTargetYear1)) * 100, '%', 2),
      },
      {
        title: '一般管理費',
        title2: 'その他管理経費',
        value1: numberWithCommas(cal.Y800()),
        value2: numberWithCommas(cal.Y800()),
        value3: addCommas((cal.Y800() / cal.Y101(TTargetYear1)) * 100, '%', 2),
      },
      {
        title: '営業利益',
        title2: '営業利益',
        value1: '',
        value2: numberWithCommas(cal.Y106(1)),
        value3: addCommas((cal.Y106(1) / cal.Y101(1)) * 100, '%', 2),
      },
    ],
  };

  return {
    configTable1,
  };
};
export const configTableForScaleUp = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);
  cal.updateTrainingResult(trainingResult);

  const configTable2 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3', 'value4', 'value5'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '１年目',
      },
      {
        field: 'value2',
        name: '２年目',
      },
      {
        field: 'value3',
        name: '３年目',
      },
      {
        field: 'value4',
        name: '４年目',
      },
      {
        field: 'value5',
        name: '５年目',
      },
    ],
    data: [
      {
        title: '規模拡大の有無',
        value1: '-',
        value2: cal.Q0801001() === 1 ? 'あり' : 'なし',
        value3: cal.Q0801002() === 1 ? 'あり' : 'なし',
        value4: cal.Q0801003() === 1 ? 'あり' : 'なし',
        value5: cal.Q0801004() === 1 ? 'あり' : 'なし',
      },
      {
        title: '圃場数',
        value1: 1,
        value2: 1 + cal.Q0801001(),
        value3: 1 + cal.Q0801001() + cal.Q0801002(),
        value4: 1 + cal.Q0801001() + cal.Q0801002() + cal.Q0801003(),
        value5: 1 + cal.Q0801001() + cal.Q0801002() + cal.Q0801003() + cal.Q0801004(),
      },
    ],
  };

  const configTable3 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '初期投資費用',
      },
      {
        field: 'value2',
        name: '減価償却費（年）',
      },
      {
        field: 'value3',
        name: '減価償却費（月）',
      },
    ],
    data: [
      {
        title: ' ',
        value1: numberWithCommas(cal.Y201(TTargetYear1)),
        value2: numberWithCommas(cal.Y501(TTargetYear1)),
        value3: numberWithCommas(cal.M501(TTargetYear1)),
      },
    ],
  };

  const configTable4 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2', 'value3', 'value4', 'value5'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '1年目',
      },
      {
        field: 'value2',
        name: '2年目',
      },
      {
        field: 'value3',
        name: '3年目',
      },
      {
        field: 'value4',
        name: '4年目',
      },
      {
        field: 'value5',
        name: '5年目',
      },
    ],
    data: [
      {
        title: '収量増加割合',
        value1: '-',
        value2: addCommas(cal.Q0801005(), '倍', 1),
        value3: addCommas(cal.Q0801006(), '倍', 1),
        value4: addCommas(cal.Q0801007(), '倍', 1),
        value5: addCommas(cal.Q0801008(), '倍', 1),
      },
      {
        title: '10aあたりの収量',
        value1: addCommas(cal.Y415(1), 't', 1),
        value2: addCommas(cal.Y415(2), 't', 1),
        value3: addCommas(cal.Y415(3), 't', 1),
        value4: addCommas(cal.Y415(4), 't', 1),
        value5: addCommas(cal.Y415(5), 't', 1),
      },
      {
        title: '収量合計',
        value1: addCommas(cal.Y416(1), 't', 1),
        value2: addCommas(cal.Y416(2), 't', 1),
        value3: addCommas(cal.Y416(3), 't', 1),
        value4: addCommas(cal.Y416(4), 't', 1),
        value5: addCommas(cal.Y416(5), 't', 1),
      },
    ],
  };

  return {
    configTable2,
    configTable3,
    configTable4,
  };
};

import actionCreatorFactory, { AnyAction, Action } from 'typescript-fsa';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { Epic, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { omit } from 'lodash';

type TAnyActions<T = any> = Action<T> | Action<T>[];
type TResAnyActions<T = any> = (res: T) => TAnyActions<T>;
type TAsyncEpicProp = {
  asyncFns: Observable<any>;
  previous?: TAnyActions;
  next?: TResAnyActions | TAnyActions;
  error?: TResAnyActions | TAnyActions;
  complete?: TAnyActions;
};

const ac = actionCreatorFactory('[lib/asyncActionWithCallback]');
const actions = {
  start: ac<TAsyncEpicProp>('start'),
  execute: ac<Omit<TAsyncEpicProp, 'previous'>>('execute'),
};

export const asyncActionWithCallback = actions.start;

const toArray = <T>(v: T | T[]): T[] => (Array.isArray(v) ? v : [v]);
const asyncStart: Epic<AnyAction, Action<Pick<TAsyncEpicProp, 'previous'> | Omit<TAsyncEpicProp, 'previous'>>, any> = (
  action$,
) =>
  action$.pipe(
    ofAction(actions.start),
    mergeMap(({ payload }) => {
      const nextAction = actions.execute(omit(payload, ['previous']));
      return payload.previous ? [...toArray(payload.previous), nextAction] : [nextAction];
    }),
  );

const nextAction = (action: TResAnyActions | TAnyActions | undefined, param: any): Action<any>[] =>
  // eslint-disable-next-line no-nested-ternary
  !action
    ? []
    : typeof action === 'function'
    ? ([action(param)].flat() as Action<any>[])
    : ([action].flat() as Action<any>[]);

const asyncExecute: Epic<AnyAction, Action<any>, any> = (action$) =>
  action$.pipe(
    ofAction(actions.execute),
    mergeMap(({ payload }) => {
      const complete: Action<any>[] = payload.complete ? [payload.complete].flat() : [];
      return payload.asyncFns.toPromise().then(
        (res): Action<any>[] => [...nextAction(payload.next, res), ...complete],
        (err): Action<any>[] => [...nextAction(payload.error, err), ...complete],
      );
    }),
    mergeMap((acs) => [...acs]),
  );
export const commonAsyncEpics = combineEpics(asyncStart, asyncExecute);

import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import styles from './style.module.scss';
import './style.css';

const Loading = () => {
  const { isShowLoading } = useSelector((state: AppState) => state.pages.ui);

  return isShowLoading ? (
    <div className={styles.loadingWrap}>
      <Spin size="large" />
    </div>
  ) : (
    <></>
  );
};

export default Loading;

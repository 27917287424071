import { Action } from 'redux';

import { ApiServerConfig } from '../config/server';
import { ApiClient } from './axios';

export const apiClient = ApiClient(ApiServerConfig);

type Handlers<State, Types extends string, Actions extends Action<Types>> = {
  readonly [Type in Types]: (state: State, action: Actions) => State;
};
export const createReducer =
  <State, Types extends string, Actions extends Action<Types>>(
    initialState: State,
    handlers: Handlers<State, Types, Actions>,
  ) =>
  (state = initialState, action: Actions) => {
    if (Object.prototype.hasOwnProperty.call(handlers, 'action.type')) {
      return handlers[action.type as Types](state, action);
    }
    return state;
  };

import { TUserDetail } from 'types/groups';
import { isEmptyObject } from 'libs/helpers/functions';
import { AxiosRequestConfigWithFns } from '../../libs/axios';
import { BASE_API_URL } from '../../constants';

const keyAuthToken: string = 'access_token';
const keyRefreshToken: string = 'refresh_token';
const keyCurrentUser: string = 'current_user';

export const setToken = (token: string = ''): void =>
  token ? localStorage.setItem(keyAuthToken, token) : localStorage.removeItem(keyAuthToken);

export const setRefreshToken = (refeshToken: string = ''): void =>
  refeshToken ? localStorage.setItem(keyRefreshToken, refeshToken) : localStorage.removeItem(keyRefreshToken);

export const getRefreshToken = (): string => localStorage.getItem(keyRefreshToken) ?? '';
export const getAccessToken = (): string | void => localStorage.getItem(keyAuthToken) ?? '';

export const saveCurrentUserToLS = (user: TUserDetail) => {
  if (user.id) {
    localStorage.setItem(keyCurrentUser, JSON.stringify(user));
  } else {
    localStorage.removeItem(keyCurrentUser);
  }
};

export const getCurrentFromLS = (): TUserDetail => {
  let data = {};
  const userLS = localStorage.getItem(keyCurrentUser);
  if (userLS) {
    data = JSON.parse(userLS);
  }
  return data;
};

export const checkUserInfo = !isEmptyObject(getCurrentFromLS()) && (getAccessToken() && getRefreshToken()) !== '';

export const ApiServerConfig: AxiosRequestConfigWithFns = {
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 5000,
  getToken: () => localStorage.getItem(keyAuthToken) ?? '',
};

import { AxiosRequestCustomConfig, RequestParameter, AxiosResponse } from '../libs/axios';
import { apiClient } from '../libs/helpers';

const getList: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'https://api.github.com/search/repositories?q=+language:javascript+created:%3E2016-10-01&sort=stars&order=desc',
};

export const githubRepository = {
  getList: apiClient.getFn(getList),
};

import userAction from 'modules/user/user.action';
import React from 'react';
import { useDispatch } from 'react-redux';
import { TChangePasswordBody } from 'types/auth';
import ChangePassword from './ChangePassword';

const ChangePasswordContainer = () => {
  const dispatch = useDispatch();

  const changePassword = (data: TChangePasswordBody) => dispatch(userAction.changePassword(data));

  return <ChangePassword changePassword={changePassword} />;
};

export default ChangePasswordContainer;

import { TOptionsQuery } from 'types/common';
import actionCreatorFactory from 'typescript-fsa';
import { TGroupsClassDetail } from 'types/groupsClass';

const ac = actionCreatorFactory();

export default {
  getGroupsClass: ac<TOptionsQuery<TGroupsClassDetail>>('getGroupsClass'),
  getGroupsClassModal: ac<TOptionsQuery<TGroupsClassDetail>>('getGroupsClassModal'),
  createGroupsClass: ac<TGroupsClassDetail>('createGroupsClass'),
  updateGroupsClass: ac<TGroupsClassDetail>('updateGroupsClass'),
  getDetailGroupClass: ac<number>('getDetailGroupClass'),
  deleteGroupsClass: ac<number>('deleteGroupsClass'),
};

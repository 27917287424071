import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { replacePathParams } from 'libs/helpers/functions';
import { Link, useParams } from 'react-router-dom';
import { routers } from 'constants/router';
import { EPath } from 'constants/routes';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { matchPath, useLocation } from 'react-router';
import styles from './style.module.scss';
import { TITLE_WORK } from './TitleWork';

const HeaderTraining = () => {
  const { trainingId } = useParams<{ trainingId: string }>();
  const { trainingResult } = useSelector((state: AppState) => state.pages);
  const [titleWork, setTitleWork] = useState('');
  const location = useLocation();

  useEffect(() => {
    const currentPath = Object.values(routers).find((router) =>
      matchPath(location.pathname, {
        path: router.path,
        exact: router.exact,
      }),
    );
    const router = currentPath?.name ?? '';
    setTitleWork(TITLE_WORK[router]);
  }, []);

  return (
    <Row>
      <Col span={12}>
        <Row>
          <p>設問回答</p>
          <p className={styles.title}>{trainingResult.training?.name ?? ''}</p>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <p>{titleWork}</p>
        </Row>
      </Col>
      <Col span={12}>
        <Row className={styles.contentRight}>
          <Button
            onClick={() => {
              window.open(routers.U6.path.replace(':trainingId', trainingId), '_blank');
            }}
          >
            <Typography.Text underline>使い方・マニュアル</Typography.Text>
          </Button>
          <Link
            className={styles.contentRightMg}
            to={replacePathParams(EPath.U4_Menu, { trainingId: trainingId ?? '' })}
          >
            <Button>課題の目次へ</Button>
          </Link>
        </Row>
        {/* <Row className={styles.contentRightMg}>
        </Row> */}
      </Col>
    </Row>
  );
};

export default HeaderTraining;

import React from 'react';
import { Button, Card, Col, Row } from 'antd';
import { TUiState } from 'modules/ui/ui.reducer';
import { TTrainingResult, TAnswerTrainingResults } from 'types/trainingResult';
import c from 'clsx';
import { replacePathParams } from 'libs/helpers/functions';
import { EPath } from 'constants/routes';
import { Link } from 'react-router-dom';
import styles from './style.module.scss';
import { listStepsTrainingMenu, listU8TrainingMenu } from './AssignmentsMenu.state';

type Props = {
  uiState: TUiState;
  trainingResultState: TTrainingResult;
};
const AssignmentsMenu = ({ uiState, trainingResultState }: Props) => {
  const { isShowLoading } = uiState;

  // Todo check invalid step
  // const isActiveStep = (
  //   currentStep: TAnswerTrainingResults,
  //   prevStep: TAnswerTrainingResults,
  //   nextStep: TAnswerTrainingResults,
  // ) => !!currentStep?.isCompleted || ((!prevStep || !!prevStep?.isCompleted) && (!nextStep || !nextStep?.isCompleted));

  return (
    <div className={styles.assignmentsMenuWrap}>
      <Card
        loading={isShowLoading}
        className={styles.cardWrap}
        title={
          <div className={styles.assignmentsMenuTitle}>
            <p>課題目次</p>
            <p>{trainingResultState?.training?.name}</p>
          </div>
        }
      >
        <div className={styles.assignmentsMenuContent}>
          <p>はじめに</p>
          <Row gutter={[30, 30]}>
            <Col xs={12} sm={12} lg={12} xl={8} xxl={6}>
              <Button className={styles.card}>
                <Link to={replacePathParams(EPath.U5, { trainingId: trainingResultState?.training?.id ?? '' })}>
                  業務命令書
                </Link>
              </Button>
            </Col>
            <Col xs={12} sm={12} lg={12} xl={8} xxl={6}>
              <Button className={styles.card}>
                <Link to={replacePathParams(EPath.U6, { trainingId: trainingResultState?.training?.id ?? '' })}>
                  使い方・マニュアル
                </Link>
              </Button>
            </Col>
          </Row>
        </div>

        <div className={styles.assignmentsMenuContent}>
          <p>基本設定</p>
          <Row gutter={[30, 30]}>
            {listStepsTrainingMenu.map((stepMenu) => {
              const currentStep = trainingResultState[
                `step${stepMenu.step}` as keyof TTrainingResult
              ] as TAnswerTrainingResults;
              // Todo check invalid step
              // const prevStep = trainingResultState[
              //   `step${stepMenu.step - 1}` as keyof TTrainingResult
              // ] as TAnswerTrainingResults;
              // const nextStep = trainingResultState[
              //   `step${stepMenu.step + 1}` as keyof TTrainingResult
              // ] as TAnswerTrainingResults;
              // const activeStep = isActiveStep(currentStep, prevStep, nextStep);

              return (
                <Col xs={12} sm={8} lg={8} xl={8} xxl={6} key={stepMenu.step}>
                  <Button className={styles.card}>
                    <Link
                      to={replacePathParams(stepMenu.path, { trainingId: trainingResultState?.training?.id ?? '' })}
                    >
                      <span className={styles.titleTop}>{stepMenu.titleTop}</span>
                      <span className={styles.titleBottom}>{stepMenu.titleBottom}</span>
                    </Link>
                  </Button>
                  <p className={c(styles.status, currentStep?.isCompleted ? '' : styles.statusNotEntered)}>
                    {currentStep?.isCompleted ? '入力済' : '未入力'}
                  </p>
                </Col>
              );
            })}
          </Row>
        </div>

        <div className={styles.assignmentsMenuContent}>
          <p>シミュレーション結果</p>
          <Row gutter={[30, 30]}>
            {listU8TrainingMenu.map((result) => (
              <Col xs={12} sm={8} lg={8} xl={8} xxl={6} key={result.key}>
                <Button disabled={!trainingResultState.isCompleted} className={styles.card}>
                  <Link
                    to={{
                      pathname: replacePathParams(EPath.U8, { trainingId: trainingResultState?.training?.id ?? '' }),
                      state: result.routeState,
                    }}
                  >
                    {result.title}
                  </Link>
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default AssignmentsMenu;

import React from 'react';
import { Route } from 'react-router-dom';

const RoutePublic = ({
  component: Component,
  path,
  exact = false,
}: {
  component: React.FC<any>;
  path: string;
  exact: boolean;
}) => <Route path={path} exact={exact} render={(props) => <Component {...props} />} />;

export default RoutePublic;

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Row, Image } from 'antd';
import ButtonFooter from 'components/ButtonFooter/ButtonFooter';
import EditTraining from 'libs/hook/EditTraining';
import { BASE_UPLOAD_URL } from 'constants/index';
import { routers } from 'constants/router';
import { useHistory, useParams } from 'react-router-dom';
import { TTrainingResult } from 'types/trainingResult';
import HeaderTraining from 'components/HeaderTraining';
import styles from './style.module.scss';

const AssignmentsRfp: React.FC<{ trainingResult: TTrainingResult }> = ({ trainingResult }) => {
  const { trainingId } = useParams<{ trainingId: string }>();
  const history = useHistory();
  const div = useRef<HTMLDivElement | null>(null);

  EditTraining();

  const handelClickNext = () => {
    history.push(routers.U6.path.replace(':trainingId', trainingId));
  };
  const [active, setActive] = useState<boolean>(true);

  const handleScroll = useCallback(() => {
    if (div.current && Math.abs(div.current.scrollHeight - div.current.scrollTop - div.current.clientHeight) < 1)
      setActive(false);
  }, [setActive]);
  useEffect(() => {
    if (div.current) handleScroll();
  }, []);
  const summary = trainingResult?.training?.summary || '';
  const multiLineText = summary.split('\n').map((item) => (
    <React.Fragment key={item}>
      {item}
      <br />
    </React.Fragment>
  ));
  return (
    <>
      <div className={styles.assignmentsRfpWrap}>
        <HeaderTraining />
        <Row>
          <p style={{ marginTop: '52px' }}>業務命令書の内容を最後まで読み、画面下の「進む」ボタンを押しましょう。</p>
        </Row>
        <div className={styles.contentWrap} onScroll={handleScroll} ref={div}>
          <div className={styles.contentWrap__bottom}>
            <div className={styles.left}>
              <p>{multiLineText}</p>
            </div>
            <div className={styles.right}>
              {trainingResult?.training?.picture && <Image src={BASE_UPLOAD_URL + trainingResult?.training?.picture} />}
            </div>
          </div>
        </div>
      </div>
      <ButtonFooter active={active} handelClickNext={handelClickNext} />
    </>
  );
};
export default AssignmentsRfp;

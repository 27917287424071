import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

import siteEpic from 'modules/epic';
import rootReducer from 'modules/reducer';

import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { commonAsyncEpics } from '../libs/reduxObservableUtils';

const epicMiddleware = createEpicMiddleware();
const middleware: [Middleware] = [epicMiddleware];

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');

  middleware.push(createLogger({ collapsed: true }));
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const reducers = combineReducers({
  router: connectRouter(history),
  pages: rootReducer,
});
const rootEpic = combineEpics(siteEpic, commonAsyncEpics);

middleware.push(routerMiddleware(history));

const configStore = (initialState = {}) => {
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middleware))(createStore);

  const store = createStoreWithMiddleware(reducers, initialState);

  epicMiddleware.run(rootEpic);

  return {
    store,
  };
};

const { store } = configStore();
export { store };
export type AppState = ReturnType<typeof reducers>;

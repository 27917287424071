/* eslint-disable complexity */
import React from 'react';
// import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import { OPTIONS_TABLE1, THEME_TABLE } from './AssignmentQuestion3.state';

const AssignmentQuestionForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    {/* row 1 */}
    <div>
      <p>消耗品・資材等経費（年間）</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={OPTIONS_TABLE1} themeCfg={THEME_TABLE} />
      )}
    </div>
  </div>
);

export default AssignmentQuestionForm;

import { from } from 'rxjs';
import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { debounceTime, map } from 'rxjs/operators';
import actions from './github.action';
import { asyncActionWithCallback, WrapAction } from '../../libs/reduxObservableUtils';
import { githubRepository } from '../../services/github.repo';
import { githubModule } from './github.reducer';
import { errorActions } from '../error/error.action';

export const fetchRepositories: Epic<AnyAction, WrapAction<typeof asyncActionWithCallback>> = (action$) =>
  action$.pipe(
    ofAction(actions.getList),
    debounceTime(1000),
    map(({ payload }) =>
      asyncActionWithCallback({
        asyncFns: from(githubRepository.getList(payload)),
        next: (v: any) => githubModule.actions.save(v),
        error: (v: any) => errorActions.throwError(v),
      }),
    ),
  );

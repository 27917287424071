import { DEFAULT_LIMIT_PAGINATION } from 'constants/pagination';
import { TOptionsQuery } from 'types/common';
import { groupsClassRepositories } from 'repositories/groupsClass';
import { TGroupsClassDetail } from 'types/groupsClass';

const DEFAULT_GROUPS_LIMIT = DEFAULT_LIMIT_PAGINATION;
const DEFAULT_GROUPS_PAGE = 1;

const getGroups = async (params: TOptionsQuery<any>) => {
  const res = await groupsClassRepositories.getGroupsClass({
    condition: {
      ...params,
      limit: params.limit ?? DEFAULT_GROUPS_LIMIT,
      page: params.page ?? DEFAULT_GROUPS_PAGE,
    },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const createGroups = async (data: TGroupsClassDetail) => {
  const res = await groupsClassRepositories.createGroupsClass({
    body: data,
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};

const updateGroups = async (data: TGroupsClassDetail) => {
  const { id, ...body } = data;
  const res = await groupsClassRepositories.updateGroupsClass({
    body,
    pathParameters: { id },
  });

  if (res instanceof Error) {
    throw res;
  }

  return res.data;
};
const deleteGroups = async (id: number) => {
  const res = await groupsClassRepositories.deleteGroupsClass({
    pathParameters: { id },
  });

  if (res instanceof Error) {
    throw res;
  }
  return id;
};

const getDetailGroup = async (id: number) => {
  const res = await groupsClassRepositories.getDetailGroupClass({
    pathParameters: { id },
  });

  if (res instanceof Error) {
    throw res;
  }
  return res.data;
};
export const groupsClassServices = {
  getGroups,
  createGroups,
  deleteGroups,
  updateGroups,
  getDetailGroup,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  repositories: {} as any,
  status: 'idle',
};

type TRepo = typeof initialState;

const _module = createSlice({
  name: '[pages/github]',
  initialState,
  reducers: {
    save(state: TRepo, action: PayloadAction<TRepo>) {
      return {
        ...state,
        repositories: action.payload,
      };
    },
    clearError() {
      return initialState;
    },
  },
});

export const githubModule = _module;

import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import SheetTable from 'components/SheetTable/SheetTable';
import React from 'react';

const RowIncome: React.FC<{ configTable: any }> = ({ configTable }) => (
  <Row
    style={{ marginTop: '20px' }}
    gutter={[
      { xs: 0, sm: 20 },
      { xs: 20, sm: 0 },
    ]}
  >
    <ChartTitle title="代表者、家族労働者（専従者）の年間所得" />
    <Col xs={24} sm={18} style={{ minHeight: '200px' }}>
      {configTable?.incomeTable && (
        <SheetTable dataCfg={configTable?.incomeTable} options={{ ...configTable.OPTIONS_TABLE, height: 200 }} />
      )}
    </Col>
  </Row>
);
export default RowIncome;

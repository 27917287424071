/* eslint-disable complexity */
import React from 'react';
import ColumnChart from 'components/ColumnChart/ColumnChart';
import PieChart from 'components/PieChart/PieChart';
import styles from './style.module.scss';
import SheetTable from '../../../components/SheetTable/SheetTable';
import {
  OPTIONS_TABLE_LAND_COST,
  OPTIONS_TABLE1,
  THEME_TABLE,
  OPTIONS_TABLE2,
  OPTIONS_TABLE3,
  OPTIONS_TABLE4,
  OPTIONS_TABLE5,
  OPTIONS_TABLE6,
  OPTIONS_TABLE7,
  OPTIONS_TABLE8,
} from './AssignmentQuestion2.state';

const AssignmentQuestionForm: React.FC<{ configTable: any }> = ({ configTable }) => (
  <div>
    <div>
      <p>土地（購入時は購入費、借入時は年間費を表示）</p>
      {configTable?.configTableLandCost && (
        <SheetTable
          dataCfg={configTable?.configTableLandCost}
          options={OPTIONS_TABLE_LAND_COST}
          themeCfg={THEME_TABLE}
        />
      )}
    </div>
    <div>
      <p>施設</p>
      {configTable?.configTable1 && (
        <SheetTable dataCfg={configTable?.configTable1} options={OPTIONS_TABLE1} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 2 */}
    <div>
      <p>機械</p>
      {configTable?.configTable2 && (
        <SheetTable dataCfg={configTable?.configTable2} options={OPTIONS_TABLE2} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 3 */}
    <div>
      <p>モニタリング装置</p>
      {configTable?.configTable3 && (
        <SheetTable dataCfg={configTable?.configTable3} options={OPTIONS_TABLE3} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 4 */}
    <div>
      <p>環境制御装置</p>
      {configTable?.configTable4 && (
        <SheetTable dataCfg={configTable?.configTable4} options={OPTIONS_TABLE4} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 5 */}
    <div>
      <p>他大型機械</p>
      {configTable?.configTable5 && (
        <SheetTable dataCfg={configTable?.configTable5} options={OPTIONS_TABLE5} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 6 */}
    <div>
      <p>機器の稼働頻度</p>
      {configTable?.configTable6 && (
        <SheetTable dataCfg={configTable?.configTable6} options={OPTIONS_TABLE6} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 7 */}
    <div>
      <p>初期投資合計、減価償却費（土地代除く）</p>
      {configTable?.configTable7 && (
        <SheetTable dataCfg={configTable?.configTable7} options={OPTIONS_TABLE7} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 8 */}
    <div className={styles.mt_20}>
      {configTable?.dataChart1 && <ColumnChart configChart={configTable?.dataChart1} />}
    </div>
    {/* row 9 */}
    <div>
      <p>シミュレーションの結果、収量が確定しました。</p>
      {configTable?.configTable8 && (
        <SheetTable dataCfg={configTable?.configTable8} options={OPTIONS_TABLE8} themeCfg={THEME_TABLE} />
      )}
    </div>
    {/* row 10 */}
    <div>{configTable?.dataPieChart && <PieChart configChart={configTable?.dataPieChart} />}</div>
    <div>{configTable?.dataChart2 && <ColumnChart configChart={configTable?.dataChart2} />}</div>
  </div>
);

export default AssignmentQuestionForm;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { message as messageAntd } from 'antd';
import { messageModule } from 'modules/message/message.reducer';

const MessageNotification = () => {
  const dispatch = useDispatch();
  const { error, success, message } = useSelector((state: AppState) => state.pages.message);
  const resetState = () => dispatch(messageModule.actions.reset());

  useEffect(() => {
    if (success && message) {
      messageAntd.success(message);
      resetState();
    }
  }, [success, message]);

  useEffect(() => {
    if (error && message) {
      messageAntd.error(message);
      resetState();
    }
  }, [error, message]);
  return <></>;
};

export default MessageNotification;

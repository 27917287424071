type DataType = {
  id: number;
  name: string;
  title: string;
  img: string;
};
export const data: DataType[] = [
  {
    id: 1,
    name: '損益計算書',
    title:
      '売上、売上原価、売上総利益、販管費、営業利益、純利益を確認しましょう。\n 目標の純利益に達していますか？改善すべき項目はどこか検討しましょう。',
    img: '/images/money_choubo.svg',
  },
  {
    id: 2,
    name: '投資計画',
    title: '投資内容、投資額を確認しましょう。',
    img: '/images/tractor_man.svg',
  },
  {
    id: 3,
    name: '資金繰計画',
    title:
      '現金がマイナスになるときがないか確認しましょう。\n 黒字でも、現金がなくなれば倒産です。費用を抑えるか借金をするか検討しましょう。',
    img: '/images/money_yuushi.svg',
  },
  {
    id: 4,
    name: '生産計画',
    title:
      '売上利益が低い場合、生産計画を見直しましょう。\n 売上を上げるにはどうするべきか、売上原価を抑えるにはどうするべきか検討しましょう。',
    img: '/images/job_nouka.svg',
  },
  {
    id: 5,
    name: '販売計画',
    title: '営業利益が低い場合、販売計画を見直しましょう。\n 営業利益が最大化する販路と売価を検討しましょう。',
    img: '/images/cooking_jitsuen_hanbai.svg',
  },
];

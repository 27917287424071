import { AxiosError, AxiosRequestConfig, AxiosResponse as TAxiosResponse } from 'axios';
import { IndexedObject } from 'constants/types';
import { replacePathParams } from 'libs/helpers/functions';
import { omit } from 'lodash';
import { TPagination } from 'types/common';
import { AxiosHelper } from './axiosHelper';

declare module 'axios' {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

type ConfigFns = {
  getToken: () => string;
};

export type AxiosRequestConfigWithFns = AxiosRequestConfig & ConfigFns;

const initialConfigFns: Required<ConfigFns> = {
  getToken: () => '',
};

const pickAxiosRequestConfig = (config: AxiosRequestConfigWithFns): AxiosRequestConfig =>
  omit(config, Object.keys(initialConfigFns));

const getConfigFn = (key: keyof ConfigFns) => (config: AxiosRequestConfigWithFns) =>
  config[key] || initialConfigFns[key];

const getToken = (config: AxiosRequestConfigWithFns) => getConfigFn('getToken')(config)();

const getAuthorization = (config: AxiosRequestConfigWithFns) =>
  getToken(config) ? { authorization: `Bearer ${getToken(config)}` } : undefined;

const toHeaders = (headers: [string, string][] = []): IndexedObject<string> =>
  headers.reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {} as IndexedObject<string>);

const throwError = (error: AxiosError<IndexedObject>): Promise<AxiosResponse> => {
  const responseError: AxiosResponse = { result: false, data: error.response?.data ?? {} };
  throw responseError;
};

const successHandler = ({ data }: TAxiosResponse<AxiosResponse, any>): AxiosResponse => ({ result: true, data });

export type AxiosRequestCustomConfig<Request, Response> = {
  url: string;
};

export type AxiosRequestParameter<T> = T & {
  headers?: [string, string][];
};

export type RequestParameter = {
  body?: IndexedObject;
  condition?: IndexedObject;
  pathParameters?: IndexedObject;
};

export type AxiosResponse = {
  result: boolean;
  data: IndexedObject;
};

export interface TResponsePagination<T = IndexedObject> extends TPagination {
  results: Array<T>;
}
const errorHandler = async (error: AxiosError<IndexedObject>): Promise<AxiosResponse> => throwError(error);

const postFn =
  (commonConfig: AxiosRequestConfigWithFns) =>
  (customConfig: AxiosRequestCustomConfig<RequestParameter, AxiosResponse>) =>
  async (params: AxiosRequestParameter<RequestParameter>): Promise<AxiosResponse> =>
    AxiosHelper.getInstance(pickAxiosRequestConfig(commonConfig))
      .Axios.post(replacePathParams(customConfig.url, params.pathParameters ?? {}), params.body, {
        headers: {
          ...getAuthorization(commonConfig),
          ...toHeaders(params.headers),
        },
        params: {
          ...params?.condition,
        },
      })
      .then((response) => successHandler(response))
      .catch(errorHandler);

const getFn =
  (commonConfig: AxiosRequestConfigWithFns) =>
  (customConfig: AxiosRequestCustomConfig<RequestParameter, AxiosResponse>) =>
  async (params: AxiosRequestParameter<RequestParameter>): Promise<AxiosResponse> =>
    AxiosHelper.getInstance(pickAxiosRequestConfig(commonConfig))
      .Axios.get(replacePathParams(customConfig.url, params.pathParameters ?? {}), {
        headers: {
          ...getAuthorization(commonConfig),
          ...toHeaders(params.headers),
        },
        params: {
          ...params?.condition,
        },
      })
      .then((response) => successHandler(response))
      .catch(errorHandler);

const putFn =
  (commonConfig: AxiosRequestConfigWithFns) =>
  (customConfig: AxiosRequestCustomConfig<RequestParameter, AxiosResponse>) =>
  async (params: AxiosRequestParameter<RequestParameter>): Promise<AxiosResponse> =>
    AxiosHelper.getInstance(pickAxiosRequestConfig(commonConfig))
      .Axios.put(replacePathParams(customConfig.url, params.pathParameters ?? {}), params.body, {
        headers: {
          ...getAuthorization(commonConfig),
          ...toHeaders(params.headers),
        },
        params: {
          ...params?.condition,
        },
      })
      .then((response) => successHandler(response))
      .catch(errorHandler);

const deleteFn =
  (commonConfig: AxiosRequestConfigWithFns) =>
  (customConfig: AxiosRequestCustomConfig<RequestParameter, AxiosResponse>) =>
  async (params: AxiosRequestParameter<RequestParameter>): Promise<AxiosResponse> =>
    AxiosHelper.getInstance(pickAxiosRequestConfig(commonConfig))
      .Axios.delete(replacePathParams(customConfig.url, params.pathParameters ?? {}), {
        headers: {
          ...getAuthorization(commonConfig),
          ...toHeaders(params.headers),
        },
        params: {
          ...params?.condition,
        },
      })
      .then((response) => successHandler(response))
      .catch(errorHandler);

const patchFn =
  (commonConfig: AxiosRequestConfigWithFns) =>
  (customConfig: AxiosRequestCustomConfig<RequestParameter, AxiosResponse>) =>
  async (params: AxiosRequestParameter<RequestParameter>): Promise<AxiosResponse> =>
    AxiosHelper.getInstance(pickAxiosRequestConfig(commonConfig))
      .Axios.patch(replacePathParams(customConfig.url, params.pathParameters ?? {}), params.body, {
        headers: {
          ...getAuthorization(commonConfig),
          ...toHeaders(params.headers),
        },
        params: {
          ...params?.condition,
        },
      })
      .then((response) => successHandler(response))
      .catch(errorHandler);

export const ApiClient = (config: AxiosRequestConfigWithFns) => ({
  postFn: postFn(config),
  getFn: getFn(config),
  putFn: putFn(config),
  deleteFn: deleteFn(config),
  patchFn: patchFn(config),
});

import React from 'react';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';
import { pageSizeOptions } from 'constants/pagination';

type Props = {
  current: number;
  total: number;
  pageSize: number;
  disabled: boolean;
  onChange: (page: number, pageSize: number) => void;
  showQuickJumper?: boolean;
  showSizeChanger?: boolean;
  showLessItems?: boolean;
  showTotal?: boolean;
  hideOnSinglePage?: boolean;
};

function PaginationCustom({
  current,
  total,
  pageSize,
  disabled,
  onChange,
  showQuickJumper = true,
  showSizeChanger = true,
  showLessItems = true,
  showTotal = true,
  hideOnSinglePage = false,
}: Props) {
  const showTotalFunc: PaginationProps['showTotal'] = (totalItem) => `Total ${totalItem} items`;

  return (
    <Pagination
      current={current}
      total={total}
      pageSize={pageSize}
      disabled={disabled}
      onChange={onChange}
      showTotal={showTotal ? showTotalFunc : undefined}
      showQuickJumper={showQuickJumper}
      showSizeChanger={showSizeChanger}
      pageSizeOptions={pageSizeOptions}
      showLessItems={showLessItems}
      hideOnSinglePage={hideOnSinglePage}
    />
  );
}

export default PaginationCustom;

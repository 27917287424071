import React from 'react';
import { useDispatch } from 'react-redux';
import userAction from 'modules/user/user.action';
import { TUser } from 'types/auth';
import Login from './Login';

const LoginContainer = () => {
  const dispatch = useDispatch();

  const login = (data: TUser) => dispatch(userAction.login(data));

  return <Login login={login} />;
};

export default LoginContainer;

import { ThemeName, generatePalette, getPalette } from '@antv/s2';
import { TTemplate } from 'types/template';
import { TTrainingResult } from 'types/trainingResult';
import { Calculator } from 'libs/calculators';
import { numberWithCommas } from 'libs/helpers/functions';

const TTargetYear = 1;

export const configTable1 = (template: TTemplate, trainingResult: TTrainingResult) => {
  const cal = new Calculator(template, trainingResult);
  cal.updateTrainingResult(trainingResult);

  const getValueByFieldName = (fieldName: string): number => cal.getValueOfQuestionId(fieldName, 'step3');

  const configTable1 = {
    fields: {
      rows: ['title'],
      values: ['value1', 'value2'],
    },
    meta: [
      {
        field: 'title',
        name: '項目',
      },
      {
        field: 'value1',
        name: '10aあたり費用',
      },
      {
        field: 'value2',
        name: '費用合計',
      },
    ],
    data: [
      {
        title: 'ガソリン代',
        value1: numberWithCommas(getValueByFieldName('Q0301001')),
        value2: numberWithCommas(cal.Y1420(TTargetYear)),
      },
      {
        title: '水道光熱費',
        value1: numberWithCommas(getValueByFieldName('Q0301002')),
        value2: numberWithCommas(cal.Y1421(TTargetYear)),
      },
      {
        title: '種苗費',
        value1: numberWithCommas(getValueByFieldName('Q0302001') * getValueByFieldName('Q0302002')),
        value2: numberWithCommas(cal.Y1430(TTargetYear)),
      },
      {
        title: '肥料費',
        value1: numberWithCommas(getValueByFieldName('Q0303001')),
        value2: numberWithCommas(cal.Y1431(TTargetYear)),
      },
      {
        title: '農薬費',
        value1: numberWithCommas(getValueByFieldName('Q0304001')),
        value2: numberWithCommas(cal.Y1432(TTargetYear)),
      },
      {
        title: '諸材料費',
        value1: numberWithCommas(getValueByFieldName('Q0305001')),
        value2: numberWithCommas(cal.Y1422(TTargetYear)),
      },
      {
        title: '小農具費',
        value1: numberWithCommas(getValueByFieldName('Q0306001')),
        value2: numberWithCommas(cal.Y1423(TTargetYear)),
      },
      {
        title: '合計',
        value1: '-',
        value2: numberWithCommas(cal.Y1400(TTargetYear)),
      },
    ],
  };

  return {
    configTable1,
  };
};

export const OPTIONS_TABLE1 = {
  height: 300,
  tooltip: {
    showTooltip: false,
  },
  showDefaultHeaderActionIcon: false,
};

export const THEME_TABLE = {
  name: 'colorful' as ThemeName,
  palette: generatePalette({
    ...getPalette('colorful'),
    brandColor: '#1D81BA',
  }),
};

import { Epic } from 'redux-observable';
import { ofAction } from 'typescript-fsa-redux-observable-of-action';
import { AnyAction } from 'typescript-fsa';
import { map } from 'rxjs/operators';
import actions from './ui.action';
import { uiModule } from './ui.reducer';

export const showLeftMenu: Epic<AnyAction> = (action$) =>
  action$.pipe(
    ofAction(actions.showLeftMenu),
    map(() => uiModule.actions.setShowLeftMenu()),
  );

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TUploadState = {
  uploaded: boolean;
};

const initialState: TUploadState = {
  uploaded: false,
};

const _module = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    uploaded(state: TUploadState, action: PayloadAction<boolean>) {
      return {
        ...state,
        uploaded: action.payload,
      };
    },
    clearState() {
      return initialState;
    },
  },
});

export const uploadModule = _module;

/* eslint-disable complexity */
import React, { useEffect, useMemo, useState } from 'react';
import { Card } from 'antd';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { Calculator, TTargetYear } from 'libs/calculators';
import { TGoalTraining } from 'types/assignments';
import { map } from 'lodash';
import styles from './style.module.scss';

const AchievementGoal = () => {
  const { trainingResult } = useSelector((state: AppState) => state.pages);
  const [goal1, setGoal1] = useState(false);
  const [goal2, setGoal2] = useState(false);
  const [goal3, setGoal3] = useState(false);
  const [goal4, setGoal4] = useState(false);
  const [goal5, setGoal5] = useState(false);

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const goalSetting = trainingResult.training?.goalSetting;

  useEffect(() => {
    const cal = new Calculator(trainingResult.training?.template ?? {}, trainingResult);
    cal.M3();
    cal.calY117All();
    if (!months.some((month) => cal.M301(month, 1) < 0)) setGoal1(true);
    if (goalSetting?.goal2.checked && goalSetting?.goal2.defaultVal[0]) {
      if (cal.Y115(goalSetting?.goal2.defaultVal[0] as TTargetYear) > 0) setGoal2(true);
    }
    // 指定年の「Y115 当期純利益」が「Y601 人件費（社長・家族労働者）」以上か？
    if (goalSetting?.goal3.checked && goalSetting?.goal3.defaultVal[0]) {
      if (cal.Y115(goalSetting?.goal3.defaultVal[0] as TTargetYear) >= cal.Y601()) setGoal3(true);
    }
    if (goalSetting?.goal4.checked && goalSetting?.goal4.defaultVal[0]) {
      if (cal.Y117(5) > goalSetting?.goal4.defaultVal[0] * 10000) setGoal4(true);
    }
    // 指定年の「Y116 当期純利益率」（所得率）が設定値以上か？
    if (goalSetting?.goal5.checked && goalSetting?.goal5.defaultVal[0]) {
      if (cal.Y116(goalSetting?.goal5.defaultVal[0] as TTargetYear) >= goalSetting?.goal5.defaultVal[1]) setGoal5(true);
    }
  }, [trainingResult]);

  const getIndexGold = useMemo(() => {
    const arr: number[] = [1];

    map(trainingResult?.training?.goalSetting, (val: TGoalTraining) =>
      val.checked ? arr.push(arr[arr.length - 1] + 1) : arr.push(arr[arr.length - 1]),
    );
    return arr;
  }, [trainingResult]);

  return (
    <>
      <Card bodyStyle={{ padding: '0' }} title={<span className={styles.card}>達成目標</span>}>
        <ul className={styles.cardContent}>
          {trainingResult.training?.goalSetting?.goal1.checked && (
            <li className={styles.goal}>
              <p className={styles.index}>{getIndexGold[0]}</p>
              <p>途中で倒産しない</p>
              <div className={styles.icon}>
                {goal1 ? <p className={styles.goalOk}>達成</p> : <p className={styles.goalNg}>未達</p>}
              </div>
            </li>
          )}
          {trainingResult.training?.goalSetting?.goal2.checked && (
            <li className={styles.goal}>
              <p className={styles.index}>{getIndexGold[1]}</p>
              <p>{trainingResult.training?.goalSetting?.goal2.defaultVal[0]}年目に単年黒字</p>
              <div className={styles.icon}>
                {goal2 ? <p className={styles.goalOk}>達成</p> : <p className={styles.goalNg}>未達</p>}
              </div>
            </li>
          )}
          {trainingResult.training?.goalSetting?.goal3.checked && (
            <li className={styles.goal}>
              <p className={styles.index}>{getIndexGold[2]}</p>
              <p>{trainingResult.training?.goalSetting?.goal3.defaultVal[0]}年目に所得目標を達成</p>
              <div className={styles.icon}>
                {goal3 ? <p className={styles.goalOk}>達成</p> : <p className={styles.goalNg}>未達</p>}
              </div>
            </li>
          )}
          {trainingResult.training?.goalSetting?.goal4.checked && (
            <li className={styles.goal}>
              <p className={styles.index}>{getIndexGold[3]}</p>
              <p>5年目に累計利益{trainingResult.training?.goalSetting?.goal4.defaultVal[0]}万円以上</p>
              <div className={styles.icon}>
                {goal4 ? <p className={styles.goalOk}>達成</p> : <p className={styles.goalNg}>未達</p>}
              </div>
            </li>
          )}
          {trainingResult.training?.goalSetting?.goal5.checked && (
            <li className={styles.goal}>
              <p className={styles.index}>{getIndexGold[4]}</p>
              <p>
                {trainingResult.training?.goalSetting?.goal5.defaultVal[0]}年目に所得率
                {trainingResult.training?.goalSetting?.goal5.defaultVal[1]}％以上
              </p>
              <div className={styles.icon}>
                {goal5 ? <p className={styles.goalOk}>達成</p> : <p className={styles.goalNg}>未達</p>}
              </div>
            </li>
          )}
        </ul>
      </Card>
    </>
  );
};

export default AchievementGoal;

import { Col, Row } from 'antd';
import ChartTitle from 'components/ChartTitle/ChartTitle';
import { DualAxesChart } from 'components/ColumnChart/ColumnChart';
import LineChart from 'components/LineChart/LineChart';
import React from 'react';

const RowTwo: React.FC<{ configTable: any }> = ({ configTable }) => (
  <Row
    style={{ marginTop: '50px' }}
    gutter={[
      { xs: 0, sm: 20 },
      { xs: 20, sm: 0 },
    ]}
  >
    <ChartTitle
      title="５か年　PLグラフ"
      subTitle="年ごとの黒字化や営業利益率、累計利益率に関する達成目標が未達の場合、下のグラフから問題となる年を判断しましょう。"
    />
    <Col xs={24} sm={12}>
      <ChartTitle title="利益推移グラフ" subTitle="" />
      {configTable?.dataLineChart && <LineChart configChart={configTable?.dataLineChart} />}
    </Col>
    <Col xs={24} sm={12}>
      <ChartTitle title="費用推移グラフ" subTitle="" />
      {configTable?.dataColumnChart && <DualAxesChart configChart={configTable?.dataColumnChart} />}
    </Col>
  </Row>
);
export default RowTwo;

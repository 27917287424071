import { routers } from 'constants/router';
import React, { useEffect, useState } from 'react';
import { matchPath, withRouter } from 'react-router-dom';
import styles from './style.module.scss';

const MainFooter: React.FC<{
  location: any;
}> = ({ location }) => {
  const [showFooter, setShowFooter] = useState<boolean>(false);

  useEffect(() => {
    const currentPath = Object.values(routers).find((router) =>
      matchPath(location.pathname, {
        path: router.path,
        exact: true,
      }),
    );

    if (currentPath) {
      setShowFooter(currentPath.isShowFooter);
    }
  }, [location.pathname]);

  return showFooter ? (
    <div className={styles.mainFooter}>
      <p>Copyright © 2022 North Star Metric株式会社 All Rights Reserved.</p>
    </div>
  ) : (
    <></>
  );
};

export default withRouter(MainFooter);

/* eslint-disable complexity */
import { TGoalTraining } from 'types/assignments';

export type TNameGoalSetting = 'goal1' | 'goal2' | 'goal3' | 'goal4' | 'goal5' | 'all';

export type TTemplateKpiItem = {
  goal1: TGoalTraining;
  goal2: TGoalTraining;
  goal3: TGoalTraining;
  goal4: TGoalTraining;
  goal5: TGoalTraining;
};
export type TStep7 = {
  step7: TTemplateKpiItem;
};

export type TFormStep7 = {
  goal1?: number;
  goal2?: number;
  goal31?: number;
  goal32?: number;
  goal4?: number;
  goal51?: number;
  goal52?: number;
};

export const getDefaultsStep7 = (templateDetail?: TTemplateKpiItem) => {
  const step7 = templateDetail || undefined;
  const training: TFormStep7 = {
    goal1: step7?.goal1.defaultVal[0] ?? 3,
    goal2: step7?.goal2.defaultVal[0] ?? 3,
    goal31: step7?.goal3.defaultVal[0] ?? 3,
    goal32: step7?.goal3.defaultVal[1] ?? 3,
    goal4: step7?.goal4.defaultVal[0] ?? 3,
    goal51: step7?.goal5.defaultVal[0] ?? 3,
    goal52: step7?.goal5.defaultVal[1] ?? 3,
  };
  return training;
};

export const getStep7 = (values: TFormStep7, checkboxKpi: TNameGoalSetting[]) => {
  const { goal1 = 0, goal2 = 0, goal31 = 0, goal32 = 0, goal4 = 0, goal51 = 0, goal52 = 0 } = values;
  const newData: TTemplateKpiItem = {
    goal1: {
      checked: checkboxKpi.includes('goal1'),
      defaultVal: [goal1],
    },
    goal2: {
      checked: checkboxKpi.includes('goal2'),
      defaultVal: [goal2],
    },
    goal3: {
      checked: checkboxKpi.includes('goal3'),
      defaultVal: [goal31, goal32],
    },
    goal4: {
      checked: checkboxKpi.includes('goal4'),
      defaultVal: [goal4],
    },
    goal5: {
      checked: checkboxKpi.includes('goal5'),
      defaultVal: [goal51, goal52],
    },
  };
  return newData;
};

import { AxiosRequestCustomConfig, AxiosResponse, RequestParameter } from 'libs/axios';
import { apiClient } from 'libs/helpers';

const getGroupsClass: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'groups',
};
const createGroupsClass: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'groups',
};

const updateGroupsClass: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'groups/:id',
};

const deleteGroupsClass: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'groups/:id',
};

const getDetailGroupClass: AxiosRequestCustomConfig<RequestParameter, AxiosResponse> = {
  url: 'groups/:id',
};
export const groupsClassRepositories = {
  getGroupsClass: apiClient.getFn(getGroupsClass),
  createGroupsClass: apiClient.postFn(createGroupsClass),
  updateGroupsClass: apiClient.patchFn(updateGroupsClass),
  deleteGroupsClass: apiClient.deleteFn(deleteGroupsClass),
  getDetailGroupClass: apiClient.getFn(getDetailGroupClass),
};
